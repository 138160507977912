@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

#footer {
  background-color: variables.$COLOR_MAIN;
  padding: 60px 0 0;

  a {
    color: white;
    text-decoration: none;
  }

  .footer-logo {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  .top-block {
    padding-bottom: 60px;

    .nav-unit {
      gap: 30px 50px;
      flex-wrap: wrap;

      /* &__item {
        width: calc(100% / 7);
      } */

      .nav-heading {
        font-size: calc(16px + 2 * (100vw - 428px) / 1492);
        font-weight: 500;
        line-height: normal;
      }

      .navbar-nav {
        margin-top: 20px;

        .nav-item {
          line-height: 2.4;
        }
      }
    }
  }

  .bottom-block {
    background-color: variables.$COLOR_TEXT;
    color: white;
    font-weight: 500;
    padding: 20px 0;
    text-align: center;

    .add-block {
      gap: 0 15px;
      justify-content: center;
      margin-bottom: 20px;
      line-height: normal;

      @include mixin.tabletP {
        flex-wrap: wrap;
        gap: 10px 0;
      }

      .contact-unit {
        gap: 0 15px;

        li {
          &::before {
            display: inline-block;
          }

          &.contact-tel {
            &::before {
              content: "TEL：";
            }

            a {
              color: white;
            }
          }

          &.contact-fax {
            &::before {
              content: "FAX：";
            }
          }
        }
      }
    }
  }

  .copyright {
    font-size: 11px;
    letter-spacing: .05em;
  }
}

#pagetop {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 10;
}
