@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* パンくずリスト */
.breadcrumb-unit {
  padding-top: 20px;
}

.breadcrumb {

  &.pankuzu {
		background-color: transparent;
		padding: 0 16px;
		margin-bottom: 0;
		align-items: center;
	}
}

.breadcrumb-item {

	a {
		color: variables.$COLOR_TEXT;
		font-size: 14px;
		text-decoration: none;
    letter-spacing: .15em;
	}

	+.breadcrumb-item {
    padding-left: 0;
		position: relative;

		&::before {
			content: "／";
      display: inline-block;
      padding: 0 10px;
		}
	}

	&.active {
		color: variables.$COLOR_TEXT;
		font-size: 14px;
		padding-right: 0;
	}
}

/* ページネーション */
.wp-pagenavi {
	border-collapse: separate;
	border-spacing: 15px 0;
	display: table;
	margin: 0 auto 20px;

	@include mixin.tabletP {
		border-spacing: 10px 0;
	}

	a,
	span {
		font-weight: bold;
		height: 40px;
		width: 40px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 0 !important;

		@include mixin.tabletP {
			height: 30px;
			width: 30px;
		}
	}
	a {
		&:hover {
			background-color: #333;
			color: #fff;
			text-decoration: none;
			opacity: .7;
		}

		&.previouspostslink,
		&.nextpostslink{

			&:hover {
				color: #333;
				background-color: transparent;
			}
		}
	}
	span {
		&.current {
			color: white;
			background-color: #333;
		}
	}
}



