@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


.main-ttl {
  &__en {
    color: variables.$COLOR_MAIN;
    font-family: variables.$FONT_ALPHA;
    font-size: calc(42px + 20 * (100vw - 428px) / 1492);
    font-weight: 500;
    letter-spacing: .05em;
  }

  &__ja {
    font-size: calc(24px + 18 * (100vw - 428px) / 1492);
  }
}

.sub-ttl {

  &__ja {
    line-height: 1;
    font-weight: 500;
  }
}