@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

[aria-hidden="true"] {
  display: block;
  transform: .5s ease;
}

[aria-hidden="false"] {
  display: none;
  transform: .5s ease;
}

.c-ratio {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}

.p-ttl-unit {
  text-align: center;
  margin-bottom: 40px;
}

.c-contact-unit {
  align-items:  center;
  gap: 0 50px;

  @include mixin.tabletP {
    flex-wrap: wrap;
    gap: 10px 0;
  }

  li {
    text-align: center;

    @include mixin.tabletP {
      width: 100%;
    }
  }
}