@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    line-height: normal;
}

h1 {
    font-size: calc(30px + 10 * (100vw - 428px) / 1492);
}

h2 {
    font-size: calc(28px + 6 * (100vw - 428px) / 1492);
}

h3 {
    font-size: calc(22px + 6 * (100vw - 428px) / 1492);
}

h4 {
    font-size: calc(20px + 4 * (100vw - 428px) / 1492);
}

h5 {
    font-size: calc(18px + 2 * (100vw - 428px) / 1492);
}

h6 {
    font-size: calc(16px + 2 * (100vw - 428px) / 1492);
}

