@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#header {
  background-color: white;
  padding-bottom: 0;
  transition: .3s ease;

  .navbar-brand {
    padding: 0;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 3000;

    @include mixin.tabletL {
      padding-left: 5px;
      position: static;
      top: auto;
      left: auto;
    }

    img {
      width: auto;
      height: calc(70 / 1920 * 100vw);
      max-height: 70px;
      min-height: 40px;
    }
  }

  .nav-link {
    color: variables.$COLOR_TEXT;
    font-weight: 600;
    padding: 0;
    text-decoration: none;
    transition: .5s ease;

    @include mixin.tabletL {
      font-size: 20px;
    }
  }

  .navbar {
    background-color: white;
    flex-direction: column;
    padding: 0;
    transition: .3s ease;

    .top-nav,
    .bottom-nav {
      background-color: white;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      position: relative;
    }

    .top-nav {
      padding: 15px 15px 0;
      transform: translateY(0);
      transition: .3s ease;

      @include mixin.tabletL {
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        padding-right: 15px;
      }

      .navbar-nav {
        gap: 0 20px;
        transition: .5s ease;

        @include mixin.tabletL {
          display: none;
        }
      }

    }

    .bottom-nav {
      justify-content: center;
      transition: .5s ease;

      .navbar-nav {
        /* justify-content: center;
        gap: 0 20px; */
        flex-wrap: wrap;

        @include mixin.tabletL {
          gap: 0;
          padding: 0;
        }
      }

      .nav-item {
        width: calc(100% / 8);

        @include mixin.tabletL {
          border-bottom: 1px #C8C8C8 solid;
          padding: 0 20px;
          position: relative;
          width: 100%;
        }

        &:first-child {
          @include mixin.tabletL {
            border-top: 1px #C8C8C8 solid;
          }
        }

        &:not(.dropdown) {
          &::after {
            @include mixin.tabletL {
              background: url(#{mixin.set_image_path('header/icon_nav_arrow.svg')}) no-repeat center center;
              background-size: 100% auto;
              content: "";
              display: block;
              width: 5px;
              height: 10px;
              position: absolute;
              top: 50%;
              right: 40px;
              transform: translateY(-50%);
            }

          }
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;

        @include mixin.tabletL {
          align-items: flex-start;
          flex-direction: column;
          letter-spacing: .07em;
          padding: 0 15px;
          height: 80px;
        }
      }

      .pc-contact-block {
        margin-left: 50px;
        padding-right: 15px;

        @media screen and (max-width: 1280px ) {
          margin-left: 30px;
        }

        @include mixin.tabletL {
          display: none;
        }

        .c-contact-unit {
          gap: 0 20px;

          @media screen and (max-width: 1280px ) {
            gap: 0 10px;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 60px;

            a {
              img {
                width: calc(60 / 1920 * 100vw);
                max-width: 60px;
                min-width: 40px;
              }
            }
          }
        }
      }

      .sp-contact-block {
        .c-contact-unit {
          gap: 0 30px;
          justify-content: center;
          margin: 40px auto 150px;

          li {
            width: auto;
          }
        }
      }

    }
  }

  .collapse {
    justify-content: center;
    transition: .5s ease;

    @include mixin.tabletL {
      height: 100vh;
    }

    &__inner {
      display: flex;

      @include mixin.tabletL {
        display: block;
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
          height: 8px;
        }

        &::-webkit-scrollbar-track {
          background: map_get(variables.$COLOR, palegray);
        }

        &::-webkit-scrollbar-thumb {
          background: map_get(variables.$COLOR, gray);
          border-radius: 5px;
        }
      }
    }
  }

  &.is-scroll {
    padding-bottom: 20px;

    .top-nav {
      transform: translateY(-100px);
      transition: .3s ease;
    }

    .bottom-nav {
      justify-content: flex-end;
      padding-bottom: 15px;
      position: absolute;
      top: 15px;
      right: 0;

      .navbar-nav {
        justify-content: flex-end;
        gap: 10px 20px;
        width: 60%;
        margin-left: auto;
        transition: .5s ease;

        @media screen and (max-width: 1280px ) {
          gap: 0 20px;
        }
      }

      .nav-item {
        width: auto;
      }

      .nav-link {
        /* height: 60px; */
        height: 40px;
        transition: .5s ease;
      }

      .collapse {
        justify-content: flex-end;
        transition: .5s ease;
        padding: 0;
      }

      .dropdown {
        &:hover {
          &::before {
            bottom: -8px;
          }
        }
      }
    }
  }

  /* ナビトグル */
  .navbar-toggler {
    border-radius: 0;
    border: none;
    padding: 5px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    position: relative;
    top: auto;
    right: auto;
    z-index: 9999;
  }

  .navbar-toggler-icon {
    background-image: none;
    background-color: variables.$COLOR_MAIN;
    width: 30px;
    height: 2px;
    display: block;
    position: absolute;
    transition: ease .5s;
    left: 50%;
    transform: translate(-50%, 0);

    &:nth-of-type(1) {
      top: 10px;
    }

    &:nth-of-type(2) {
      top: 20px;
    }

    &:nth-of-type(3) {
      top: 30px;
    }
  }

  .navbar-toggler[aria-expanded="false"],
  .navbar-toggler[aria-expanded="true"] {
    &::after {
      color: variables.$COLOR_MAIN;
      content: "";
      display: block;
      font-size: 10px;
      font-weight: 600;
      text-decoration: none;
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s ease;
    }
  }

  .navbar-toggler[aria-expanded="false"] {
    &::after {
      content: "menu";
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    &::after {
      content: "close";
    }

    .navbar-toggler-icon {

      &:nth-of-type(1) {
        top: 20px;
        transform: translateX(-50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        transform:scaleX(0);
      }

      &:nth-of-type(3) {
        top: 20px;
        transform: translateX(-50%) rotate(135deg);
      }
    }
  }

  /* ドロップダウンメニュー */
  .dropdown-menu {
    background-color: variables.$COLOR_TEXT;
    border: none;
    border-radius: 10px;
    padding: 25px 50px;
    width: calc(840 / 1440 * 100vw);
    max-width: 840px;
    transition: 0.5s ease;
    transform: translate(-50%, -2px);
    left: 50%;

    @include mixin.tabletL {
      background-color: map_get(variables.$COLOR, palegray);
      border-radius: 0;
      padding: 10px 20px;
      margin: 0 calc(50% - 50vw);
      width: 100vw;
      max-width: inherit;
      min-width: auto;
      top: -1px;
      left: 0;
      transform: translate(0, 0);
    }

    &__inner {
      box-sizing: border-box;
      display: flex;
      gap: 20px 40px;
      flex-wrap: wrap;
      position: relative;

      @include mixin.tabletL {
        gap: 10px 0;
        flex-direction: column;
        width: 100%;
      }

      &--item {

        @include mixin.tabletL {
          width: 100%;
        }
      }

      &--list {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        margin-top: 10px;

        @include mixin.tabletL {
          margin-top: 0;
          padding-left: 5px;
        }

        li {

          @include mixin.tabletL {
            padding: 0 16px;
          }

          a {
            color: white;
            font-size: 15px;
            font-weight: 500;
            text-decoration: none;

            @include mixin.tabletL {
              color: variables.$COLOR_TEXT;
              font-weight: 400;
            }

            &:before {
              @include mixin.tabletL {
                content: "－";
                display: inline-block;
              }
            }
          }
        }

      }
    }

    &.achievement
    &._left {
      left: calc(-10vw + 80px) !important;
      transform: translate(0, -2px);
    }

    &.school
    &._right {
      left: auto;
      right: calc(-10vw + 40px) !important;
      transform: translate(0, - 2px);
    }
  }

  .dropdown-item {
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    padding-left: 18px;
    text-decoration: none;
    position: relative;

    @include mixin.tabletL {
      color: variables.$COLOR_TEXT;
      font-size: 15px;
      font-weight: 400;
      padding: 10px 16px;
      text-align: left;
      position: static;
    }

    &::before {
      background: url(#{mixin.set_common_path('icon_arrow_right_green_sm.svg')}) no-repeat center center;
      content: "";
      display: block;
      width: 8px;
      height: 14px;
      position: absolute;
      top: 10px;
      left: 0;

      @include mixin.tabletL {
        display: none;
      }
    }

    &:hover {
      background-color: transparent;
      opacity: .7;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }

  /* ドロップダウントグル */
  .dropdown-toggle {
    background-color: map_get(variables.$COLOR, palegray);
    display: inline-block;
    width: 80px;
    height: 80px;

    &[data-toggle="dropdown"] {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;


      @media screen and (min-width: 992px) {
        display: none;
      }

      &::before,
      &::after {
        background: variables.$COLOR_MAIN;
        border: none;
        content: "";
        display: block;
        width: 15px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: .3s ease;
        transform: translate(-50%, -50%) rotate(0deg);

      }
    }

    &[aria-expanded="false"] {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &[aria-expanded="true"] {
      &::after {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  .dropdown {

    &:nth-of-type(9) {
      .dropdown-menu {

        &._left {
          margin-left: 20px;
          left: auto;
          transform: translate(0, -2px);
        }
      }
    }

    &:nth-of-type(10) {
      .dropdown-menu {

        &._left {
          margin-left: 20px;
          left: -12vw;
          transform: translate(0, -2px);
        }
      }
    }
  }

    /* ドロップダウンホバー処理 */
  .dropdown {
    @media screen and (min-width:992px) {
      &:hover {
        &::before {
          border-style: solid;
          border-width: 0 17px 18px 17px;
          border-color: transparent transparent #1e1e1e transparent;
          content: "";
          display: block;
          height: 0;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 100px;

          @include mixin.tabletL {
            display: none;
          }
        }

        >.dropdown-menu {
          display: block;
        }
      }
    }
  }
}
