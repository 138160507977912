@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/* 下層テンプレート */
.modContentsContainer {
  padding-bottom: 50px;
}

.modContents {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 38px;
    margin-bottom: 20px;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 38px;
    margin-bottom: 20px;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before,
  h6:before,
  h1:after,
  h2:after,
  h3:after,
  h4:after,
  h5:after,
  h6:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: none;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }

  h2 {
    background-color: variables.$COLOR_MAIN;
    color: white;
    font-size: calc(26px + 6 * (100vw - 428px) / 1492);
    font-weight: 700;
    line-height: 1.563;
    padding: 10px 20px;
    margin-bottom: 30px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 5px;
      height: calc(100% - 40px);
      background-color: map_get(variables.$COLOR, yellow);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  h3 {
    border-bottom: 3px map_get(variables.$COLOR, palegreen) solid;
    font-size: calc(24px + 4 * (100vw - 428px) / 1492);
    font-weight: 500;
    padding-bottom: 10px;
    position: relative;

    &::after {
      background-color: variables.$COLOR_MAIN;
      content: "";
      display: block;
      width: 30%;
      height: 3px;
      position: absolute;
      bottom: -3px;
      left: 0;
    }
  }

  h4 {
    color: variables.$COLOR_MAIN;
    font-size: calc(21px + 2 * (100vw - 428px) / 1492);
    font-weight: 500;
  }

  h5 {
    font-size: 17px;
    font-weight: 700;
  }

  h6 {
    border-bottom: 1px #333 solid;
    font-size: calc(16px + 4 * (100vw - 375px) / 1545);
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 60px;
  }

  ul:not(.list_1):not(.list_1 li ul):not(.wp-pagenavi):not(.modListJump):not(.toggle_panel) {
    padding-left: 20px;
    margin-bottom: 50px;

    li {
      padding-left: 24px;
      margin-bottom: 10px;
      position: relative;

      @include mixin.tabletP {
        padding-left: 30px;
      }

      &::before {
        background: variables.$COLOR_MAIN;
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  ol {
    counter-reset: ol_li;
    padding-left: 20px;
    margin-bottom: 50px;

    li {
      padding-left: 24px;
      margin-bottom: 10px;
      position: relative;

      &::before {
        counter-increment: ol_li;
        content: "" counter(ol_li);
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  }

  .wp-caption,
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  .wp-caption {
    p {
      margin: 0;
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-break: anywhere;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {}

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {}

  iframe {
    width: 100%;
    max-width: 100%;
  }

  a {
    display: inline;
      color: map_get(variables.$COLOR, blue);
      text-decoration: none;

      &:hover {
          text-decoration: none;
      }

      &:visited {
          color: map_get(variables.$COLOR, purple);
      }
  }
  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt {
    color: map_get(variables.$COLOR, blue);
    display: inline-block;
    padding-right: 35px;
    margin: 0 5px;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn_pdf {
    background: url(#{mixin.set_common_path('bg_pdf.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_doc {
    background: url(#{mixin.set_common_path('bg_doc.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_xls {
    background: url(#{mixin.set_common_path('bg_xls.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_ppt {
    background: url(#{mixin.set_common_path('bg_ppt.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_link {
    background: url(#{mixin.set_common_path('bg_blank.svg')}) no-repeat right center;
    background-size: auto;
    display: inline-block;
    padding-right: 25px;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn01 {
    background-color: variables.$COLOR_MAIN;
    border-radius: 30px;
    color: white;
    display: inline-block;
    text-decoration: none !important;
    padding: 20px;
    padding-right: 60px;
    line-height: normal;
    max-width: 100%;
    position: relative;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_right_white.svg')}) no-repeat center center;
      background-size: 100%;
      content: "";
      display: block;
      width: 17px;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;

    th,
    td {
      padding: 10px 30px;

      @include mixin.tabletP {
        padding: 10px 15px;
      }
    }

    thead {

      th,
      td {
        border: 1px #333 solid;
        font-weight: 700;
      }

      th {
        width: 30%;
      }

      td {
        width: 70%;
      }
    }

    tbody {

      tr {
        &:first-child {
          th,td {
            background-color: variables.$COLOR_TEXT;
            border: 1px #f2f2f2 solid;
            color: white;
            font-weight: 700;
          }
        }
      }

      th,td {
        border-bottom: 1px map_get(variables.$COLOR, gray) solid;
        vertical-align: top;
      }

      th {
        background-color: map_get(variables.$COLOR, palegreen);
        font-weight: 500;
        width: 50%;
      }

      td {
        background-color: white;
        width: 50%;
      }
    }

    .wp-caption {
      p {
        margin: 0;
      }

      img {
        margin: 0 auto;
        padding: 0;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &.tbl_none {
      border: none !important;
      display: table;
      table-layout: fixed;

      tr {
        &:first-child {
          th,td {
            background-color: transparent;
            border: none;
            color: variables.$COLOR_TEXT;
          }
        }
      }

      th,
      td {
        background: none;
        border: none !important;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;

        p {
          font-weight: normal;
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      border: none;
      table-layout: fixed;

      tr {
        &:first-child {
          th {
            border-top: 1px map_get(variables.$COLOR, gray) solid;
            background-color: map_get(variables.$COLOR, palegreen);
            border-bottom: 1px map_get(variables.$COLOR, gray) solid;
            color: variables.$COLOR_TEXT;
            font-weight: 500;
          }
          td {
            background-color: white;
            border-top: 1px map_get(variables.$COLOR, gray) solid;
            border-bottom: 1px map_get(variables.$COLOR, gray) solid;
            color: variables.$COLOR_TEXT;
          }
        }
      }
    }
  }
}

.toggle_panel {
  border-bottom: 1px #D7D7D7 solid;
  width: 100%;
  max-width: 100%;

  &.last-item {
    margin-bottom: 50px;
  }

  .accordion_title {
    font-size: 17px;
    font-weight: 500;
    padding: 25px 0;
    padding-right: 50px;
    margin: 0;
    text-align: left;
    position: relative;

    @include mixin.tabletP {
      padding: 15px 40px;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      color: variables.$COLOR_MAIN;
      content: "Q";
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 30px;
      left: 0;

      @include mixin.tabletP {
        left: 15px;
      }
    }

    &:after {
      background: url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 0;
      transform: translateY(-50%) rotate(0deg);
      transition-duration: 0.3s;
    }

    &.toggle_open {
      &:after {
        background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
        background-size: 100% auto;
        transform: translateY(-50%) rotate(180deg);
        transition-duration: 0.3s;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }

  .accordion_body {
    display: none;
    position: relative;

    &::before {
      color: #808080;
      content: "A";
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-size: 30px;
      font-weight: 700;
      position: absolute;
      top: 10px;
      left: 0;

      @include mixin.tabletP {
        left: 15px;
      }
    }

    p {
      margin-bottom: 0 !important;
    }
  }


  &.type1 {
    .accordion_title,
    .accordion_body {
      &::before {
        display: none;
      }
    }

    .accordion_body {
      padding: 0 20px 20px;
    }
  }

  &.type2 {
    .accordion_title,
    .accordion_body {
      padding: 25px 40px;
    }
  }

}

.flow-unit {
  margin-bottom: 60px;
  counter-reset: flow_num;

  .card-outer {
    padding-bottom: 40px;
    position: relative;

    +.card-outer {
      margin-top: 20px;
    }

    &::after {
      border-style: solid;
      border-width: 23px 11.5px 0 11.5px;
      border-color: #1e1e1e transparent transparent transparent;
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  .card-header {
    background-color: white;
    border: none;
    padding: 0;
    padding-bottom: 20px;

    .flow-ttl {
      color: variables.$COLOR_TEXT;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 0 15px;
      line-height: 1;
      padding-right: 15px;

      &::before {
        border-right: 1px variables.$COLOR_MAIN solid;
        counter-increment: flow_num;
        content: "0" counter(flow_num);
        color: variables.$COLOR_MAIN;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
        padding-right: 20px;
        white-space: nowrap;
      }
    }
  }

  .flow-card {
    background-color: map_get(variables.$COLOR, pale-gray);
    border: 1px #F5F5F5 solid;
    border-radius: 0;
    gap: 0 30px;
    line-height: normal;
    padding: 20px 26px;
    position: relative;

    .card-img {
      border-radius: 12px;
      width: 30%;

      @include mixin.tabletP {
        margin-top: 10px;
        width: 100%;
      }

      &.p-ratio {

        &::before {
          padding-top: 70.6896%;
        }
      }

      .object-fit-img {
        height: 100% !important;
      }
    }

    .card-body {

    }

    .card-text {
      line-height: normal;
    }

    &.col1 {
      padding: 40px 30px;

      @include mixin.tabletP {
        padding: 20px;
        padding-top: 30px;
      }

      .card-body {
        width: 100%;
      }
    }
  }

  &.type2 {
    .card-header {

      .flow-ttl {

        &::before {
          display: none;
        }
      }
    }
  }
}

.list_1 {
  box-sizing: border-box;
  display: flex;
  gap: 50px 70px;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 50px;
  width: 100%;

  a {
    color: variables.$COLOR_TEXT;
    padding: 15px 20px;
    padding-right: 40px;
    text-decoration: none;
    width: 100%;
    max-width: 100%;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
    }
  }

  >li {
    width: calc(100% / 2 - 35px);

    > a {
      border-bottom: 2px map_get(variables.$COLOR, gray) solid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      padding: 25px 0;
      padding-right: 30px;
      position: relative;

      &::before {
        background: url(#{mixin.set_common_path('icon_arrow_right_green_lg.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 20px;
        height: 13px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &::after {
        background-color: variables.$COLOR_MAIN;
        width: 70px;
        height: 2px;
        bottom: -2px;
        right: 0;
      }
    }

    >ul {
      padding-left: 20px;
      margin-top: 20px;

      @include mixin.tabletP {
        margin-bottom: 20px;
      }

      >li {
        display: block;
        border: none;

        +li {
          margin-top: 16px;
        }

        a {
          padding: 0;
          padding-left: 20px;

          &::before {
            background: url(#{mixin.set_common_path('icon_arrow_right_green_sm.svg')}) no-repeat center center;
            background-size: 100% auto;
            width: 8px;
            height: 14px;
            top: 8px;
            left: 0;
          }

          &.ac-toggle {
            &::before,
            &::after {
              border: none;
              background: variables.$COLOR_MAIN;
              content: "";
              display: block;
              width: 12px;
              height: 2px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0, -50%) rotate(0deg);
              transition: all .3s ease;
            }

            &::after {
              transform: translate(0, -50%) rotate(90deg);
            }

            &.is-open {
              &::after {
                transform: translate(0, -50%) rotate(0deg);
              }
            }
          }
        }

        >.acod-panel {
          display: none;
          margin-left: 20px;

          li {

            +li {
              margin-top: 10px;
            }

            a {
              font-weight: 400;
              padding: 0;
              padding-left: 10px;
              position: relative;

              &::before {
                background: none;
                content: "-";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.accordion {
  padding-left: 5px !important;
  position: relative;

  .ac-toggle {
    display: block;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: black;
      position: absolute;
      top: 50%;
      width: 18px;
      height: 1px;
      left: 0;
      transition: all 0.3s;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &::after {
      transform: translateX(-50%);
    }

    &.open {
      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        opacity: 0;
      }
    }
    span {
      display: block;
      padding-left: 20px;
    }
  }

  .content {
    display: none;
  }
}

@include mixin.tabletP {
  .modContents {

    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 10px;
    }

    .size-full {
      margin: 0 auto 10px;
      width: 100%;
    }

    .scroll_wrap {
      margin-bottom: 30px;
      width: 100%;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;

      &:before {
        content: "→スクロールできます。"
      }
    }

  .list_1 {
      display: block;

      >li {
        width: auto;
        display: block;
        padding-left: 0;

        &:nth-child(even) {
            margin-left: 0;
        }
      }
    }

    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }

    .tbl_responsive,
    .tbl_responsive thead,
    .tbl_responsive tfoot,
    .tbl_responsive tbody,
    .tbl_responsive tr,
    .tbl_responsive th,
    .tbl_responsive td {
      display: inline-block;
      width: 100% !important;
    }

    .tbl_responsive02 th,
    .tbl_responsive02 td {
      display: inline-block;
      width: 50% !important;
    }
  }
}