@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.btn {
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 30px;
  width: 250px;
  max-width: 100%;
  min-height: 60px;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 11px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  &.green {
    background-color: variables.$COLOR_MAIN;
    border: 1px variables.$COLOR_MAIN solid;
    color: white;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_right_white.svg')}) no-repeat center center;
    }

    &:hover {
      background-color: white;
      color: variables.$COLOR_MAIN;
      opacity: 1;

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_right_green_md.svg')}) no-repeat center center;
      }
    }
  }

  &.white {
    background-color: white;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_right_green_md.svg')}) no-repeat center center;
    }
  }
}

.txt-link {
  color: variables.$COLOR_MAIN;
  font-size: 16px;

  &::after {
    background: url(#{mixin.set_common_path('icon_arrow_right_green_md.svg')}) no-repeat center center;
    background-size: 100% auto;
    content: "";
    display: inline-block;
    margin-left: 5px;
    width: 16px;
    height: 10px;
  }
}