@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';

ul.modListJump {
	display: flex;
  align-items: center;
  gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 60px;
	padding: 0;

	li {
    border: 1px map_get(variables.$COLOR, gray) solid;
    border-radius: 50px;
    line-height: normal;
    padding: 10px 20px;

		&:before {
			display: none;
		}

		a{
      color: variables.$COLOR_TEXT;
			text-decoration: none;
      line-height: normal;
			padding-left: 20px;
			position: relative;

			&:before {
        background: url(#{mixin.set_common_path('icon_dropdown_green.svg')}) no-repeat center center;
        background-size: 100% auto;
				content: "";
        display: block;
        width: 10px;
        height: 6px;
				position: absolute;
				top: 10px;
				left: 5px;
			}

			&:hover {
        text-decoration: none;
			}
		}
	}
}

.c-news-list {

  li {
    &:first-child {
      .c-news-list__link {
        border-top: 1px map_get(variables.$COLOR, gray) solid;
      }
    }
  }

  &__link {
    color: variables.$COLOR_TEXT;
    border-bottom: 1px map_get(variables.$COLOR, gray) solid;
    display: flex;
    padding: 30px 0;
    text-decoration: none;

    @include mixin.tabletP {
      flex-wrap: wrap;
      padding: 20px 0;
    }

    &:hover {
      color: variables.$COLOR_TEXT;
    }
  }

  &__date {
    width: 120px;

    @include mixin.tabletP {
      width: 100%;
    }
  }

  &__txt {
    width: calc(100% - 120px);

    @include mixin.tabletP {
      width: 100%;
    }


    &.new {

      &::after {
        color: variables.$COLOR_MAIN;
        content: "NEW";
        display: inline-block;
        font-weight: 700;
        padding-left: 10px;
      }
    }
  }
}

.c-blog-list {
  color: variables.$COLOR_TEXT;
  display: flex;
  text-decoration: none;
  min-height: 115px;

  @include mixin.tabletP {
    min-height: auto;
  }

  +.c-blog-list {
    margin-top: 20px;
  }

  &:hover {
    color: variables.$COLOR_TEXT;
  }

  &__img {
    background-color: map_get(variables.$COLOR, palegreen);
    width: 140px;

    @include mixin.tabletP {
      width: 80px;
    }
  }

  &__txt--block {
    background-color: map_get(variables.$COLOR, palegray);
    padding: 20px 30px;
    width: calc(100% - 140px);

    @include mixin.tabletP {
      padding: 10px 20px;
      width: calc(100% - 80px);
    }
  }

  &__txt {
    font-weight: 700;

    &.new {
      &::after {
        color: variables.$COLOR_MAIN;
        content: "NEW";
        display: inline-block;
        font-weight: 700;
        padding-left: 10px;
      }
    }
  }

}

.c-tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 20px;
  margin-top: 10px;
  width: 100%;

  li {
    font-weight: 700;

    &:first-child {
      &::before {
        content: "関連タグ：";
        display: inline-block;
        font-weight: 400;
      }
    }
  }
}
